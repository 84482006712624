import React, { useContext } from 'react';
import { Col, Row } from 'react-bootstrap';
import simpleCheckIcon from '../../assets/image/svg/simple-check.svg';
import './styles.scss';
import GlobalContext from '../../context/GlobalContext';
import { isMobile } from 'react-device-detect';

interface Props {
  currentStep: number;
}

const RecontractOrderJourneySteps = (props: Props) => {
  const { currentStep } = props;
  const gContext: any = useContext(GlobalContext);

  const selectStep = (step) => {
    gContext.goSetRegistrationData({
      ...gContext.goGetRegistrationData(),
      checkoutStep: step,
    });
  };

  return (
    <Row className={`justify-content-center orderStepsContainer${(gContext.quoteLoading || gContext.totalLoading) ? ' steps-disabled' : ''}`}>
      {Object.keys(gContext.goGetRecontractSteps()).map((key, i) => {
        const stepNo = parseInt(key);
        const step = gContext.goGetRecontractSteps()[stepNo];
        return (
          <Col
            xs={2}
            key={step.name}
            className={`${
              i < Object.entries(gContext.goGetRecontractSteps()).length - 1 ? 'mr-4' : 'mr-0'
            } ${isMobile ? 'mb-2' : ''}`}
          >
            <Row
              className={`step-container ${step.stepVisited ? 'step-visited' : ''}`}
              onClick={() => (step.stepVisited ? selectStep(stepNo) : null)}
            >
              <Col
                xs={12}
                className={`orderStepsStep pb-2 ${stepNo === currentStep ? 'active' : ''} ${
                  stepNo < currentStep ? 'completed' : ''
                }`}
              >
                <div style={{ paddingTop: 10 }} key={step.name}>
                  {stepNo < currentStep ? (
                    <div>
                      <img
                        src={simpleCheckIcon}
                        alt=""
                        className="img-fluid mr-1"
                        style={{ maxWidth: '18px', color: '#07131E' }}
                      />{' '}
                      <div className='step-info inline-block'>{step.name}</div>
                    </div>
                  ) : (
                    <div>
                      {stepNo}<div className='step-info inline-block'>. {step.name}</div>
                    </div>
                  )}
                </div>
              </Col>
            </Row>
          </Col>
        );
      })}
    </Row>
  );
};

export default RecontractOrderJourneySteps;
